import React from 'react';
import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';
import Layout from '../layouts/de';
import config from '../../config';
import { Link } from 'gatsby';
import iconLogin from '../assets/images/icons/icon-login.svg';
import iconLoginHover from '../assets/images/icons/icon-login-hover.svg';
import iconDown from '../assets/images/icons/icon-down.svg';
import iconGeography from '../assets/images/icons/icon-geography.svg';
import iconCaretDown from '../assets/images/icons/icon-caret-down.svg';
import iconCaretRight from '../assets/images/icons/icon-caret-right.svg';
import iconInovation from '../assets/images/icons/icon-inovation.svg';
import iconMoney from '../assets/images/icons/icon-money.svg';
import iconPlanet from '../assets/images/icons/icon-planet.svg';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import iconTransform from '../assets/images/icons/icon-transform.svg';
import iconMap from '../assets/images/icons/icon-map.svg';
import iconSubmit from '../assets/images/icons/icon-submit.svg';
import iconSuitcase from '../assets/images/icons/icon-suitcase.svg';
import iconPhone from '../assets/images/icons/icon-phone.svg';
import iconAddress from '../assets/images/icons/icon-address.svg';
import iconMessage from '../assets/images/icons/icon-message.svg';
import iconRightBlue from '../assets/images/icons/icon-double-right.svg';
import iconMagnifierRemove from '../assets/images/icons/icon-magnifier-remove.svg';
import iconReporting from '../assets/images/icons/icon-reporting.svg';
import iconSustainable from '../assets/images/icons/icon-sustainable-green.svg';

import keyFactsImage from '../assets/images/key-facts-2.jpg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import timboLogo from '../assets/images/timbo-logo.png';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
// import timboAfricaMobile from '../assets/images/timbo-africa-mobile.png';
import Scroll, { scrollTo } from './../components/Scroll';
import contactImage from '../assets/images/contact-background_2.jpg';
import contactImageMobile from '../assets/images/contact-background-mobile_2.jpg';
import Spinner from './../components/Spinner';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import fetch from 'unfetch';
import { BrowserView, MobileView } from 'react-device-detect';
import axios from 'axios';
import noImage from '../assets/images/no-image.png';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { loadByKey } from '../util/cache';
import { openUrl } from '../util/url';

const CONTACT_TABS = [
  {
    title: 'Niederlande',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4872.9865659860625!2d4.881036!3d52.361475!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd5b42ee3519b4885!2sActive%20Capital%20Company%20B.V.!5e0!3m2!1sen!2srs!4v1568810858388!5m2!1sen!2srs',
  },
  {
    title: 'Deutschland',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.476727847869!2d11.572546014928363!3d48.139613758999225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75f4a0d537c3%3A0x133b6a976f11c1a4!2sMaffeistra%C3%9Fe%2C%2080333%20M%C3%BCnchen%2C%20Germany!5e0!3m2!1sen!2srs!4v1600803641702!5m2!1sen!2srs',
  },
];

export default class IndexPage extends React.Component {
  responsive = {
    0: { items: 1 },
    678: { items: 2 },
    1024: { items: 3 },
    1280: { items: 4 },
  };

  homepageResponsive = {
    0: { items: 1 },
    678: { items: 1 },
    1024: { items: 1 },
  };

  cache = new InMemoryCache();

  client = new ApolloClient({
    fetchOptions: { fetch },
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      successMsg: false,
      fillFields: false,
      firstTab: true,
      secondTab: false,
      moreLoaded: false,
      spinnerShow: false,
      activeContactTab: 0,
      name: '',
      email: '',
      subject: '',
      message: '',
      teamMembers: [
        {
          id: 16,
          title: 'Albert Hartog',
          subtitle: 'Gründer & Berater',
          slug: 'team/albert-hartog',
          description1:
            'Albert Hartog ist Gründer von Active Capital Company und fungiert als Berater des Investmentteams. Albert ist Mitglied im Vorstand der Hendrik Veder Group.',
          description2:
            'Vor der Gründung von Active Capital Company war Albert CEO bei CleanLeaseFortex und konnte das Unternehmen von einer einzelnen Wäscherei zu einem industriellen Textilunternehmen mit über 20 Filialen ausbauen.',
          description3:
            'Nach seinem Studium arbeitete Albert 9 Jahre lang in Südafrika, wo er Erfahrungen in der Automobil- und Bergbauindustrie sammelte.',
        },
        {
          id: 17,
          title: 'Max Groeneveld',
          subtitle: 'Senior Berater',
          slug: 'team/max-groeneveld',
          description1:
            'Max Groeneveld ist Senior Berater bei Active Capital Company. Neben seiner Rolle bei ACC ist Max Teil des Senior Teams von EY-Parthenon. Max Groeneveld war 20 Jahre lang Partner bei OC&C Strategy Consultants, bevor OC&C 2016 Teil von EY wurde. In seiner Zeit bei OC&C leitete Max die Abteilungen Global PE Practice und Global Consumer Practice und unterstützte Private Equity-Kunden in einer Vielzahl von Branchen.',
          description2:
            'Bei Active Capital Company berät Max das Portfolio-Management- und das Investment-Management-Team bei strategischen Fragestellungen. Max ist Arzt und hat einen MBA der Universität Stanford.',
          description3: '',
        },
        {
          id: 1,
          title: 'Dick Zeldenthuis',
          subtitle: 'Geschäftsführender Partner',
          slug: 'team/dick-zeldenthuis',
          description1:
            'Dick ist Gründer und geschäftsführender Partner von Active Capital Company. Vor der Gründung von ACC war Dick als CFO für JVC Benelux tätig. In dieser Funktion führte er das Unternehmen durch konsequente Kostenkontrolle zurück in die Profitabilität. Dick begann seine Karriere bei KPMG Accountants in Den Haag.',
          description2:
            'Er ist erfahren in der Neuorganisation und Integration von Unternehmen und verfügt über weitreichendes Fachwissen in den Bereichen Finanzmanagement, Unternehmensbewertung und Due Diligence.',
          description3:
            'Dick hat einen Master in Betriebswirtschaft von der Erasmus-Universität Rotterdam, sowie Aufbauabschlüsse in Wirtschaftsprüfung (RA) und Unternehmensbewertung der Rotterdam School of Management.',
        },
        {
          id: 2,
          title: 'Mels Huige',
          subtitle: 'Partner',
          slug: 'team/mels-huige',
          description1:
            'Mels Huige kam 2013 als Investment Direktor zu Active Capital Company, bevor er 2015 Partner wurde. Er verfügt über umfangreiche Erfahrung in der Leitung von Fusions- und Übernahmeprojekten, der Strukturierung von Übernahmen und der Bewertung von Unternehmen. Er ist bei Active Capital Company für die Akquisition neuer Beteiligungen verantwortlich, sowie für Add-ons und Exits.',
          description2:
            'Zuvor arbeitete er als Direktor bei Boer & Croon Corporate Finance. Bei Boer & Croon Corporate Finance lag Mels‘ Schwerpunkt auf Fusions- und Übernahmeprozessen in den Sektoren Transport & Logistik sowie Industrie & Handel. Darüber hinaus hat Mels Erfahrung mit Zulieferern der maritimen Öl- und Gasindustrie. Mels besitzt einen Master-Abschluss in Betriebswirtschaft von der Universität Maastricht, und einen Abschluss in Unternehmensbewertung der Rotterdam School of Management. Er ist Vorstandsmitglied des Holländischen Private Equity Verbands.',
          description3:
            '',
        },
        {
          id: 21,
          title: 'Hartwig Ostermeyer',
          subtitle: 'Partner',
          slug: 'team/hartwig-ostermeyer',
          description1:
            'Hartwig Ostermeyer ist Partner bei Active Capital Company. Er leitet unsere deutsche Niederlassung in München und ist verantwortlich für lokales Deal Sourcing, die Prüfung von Investitionsmöglichkeiten und das lokale Portfoliomanagement.',
          description2:
            'Hartwig Ostermeyer begann seine berufliche Laufbahn als M&A-Berater in London mit Schwerpunkt auf dem Energiesektor. Danach war er als Projektleiter bei der Boston Consulting Group in München tätig und sammelte operative Erfahrung im Management bei Sixt. Hartwig ist Diplom-Ingenieur der Technischen Universität Berlin.',
        },
        {
          id: 26,
          title: 'Boudewijn Brouwer',
          subtitle: 'Investment Director Special Investments',
          slug: 'team/boudewijn-brouwer',
          description1: `Boudewijn Brouwer is medeverantwoordelijk voor deal-sourcing, screening van investeringsproposities, executie van (add-on) acquisities en desinvesteringen bij Active Capital Company - Special Investments. Boudewijn kan snel informatie verwerken en begrijpt het verhaal achter de cijfers. Zijn enthousiasme en brede interesse stellen hem in staat om creatieve oplossingen te vinden wanneer een bedrijf wordt geconfronteerd met uitdagende vraagstukken.`,
          description2: `Voordat Boudewijn bij Active Capital Company in dienst trad, heeft hij 10 jaar bij ABN AMRO gewerkt waar hij ervaring opdeed in Asset & Liability Management, Debt Capital Markets en Financial Restructuring. Boudewijn heeft een bachelor in Medische Natuurwetenschappen en Bedrijfseconomie, een master in Finance en is CFA Charterholder.`,
          description3: '',
        },
        {
          id: 9,
          title: 'Leonard Spigt',
          subtitle: 'Senior Investment Manager',
          slug: 'team/leonard-spigt',
          description1:
            'Leonard Spigt arbeitet als Senior Investment Manager im Team von Active Capital Company. Als Senior Investment Manager ist Leonard Spigt mitverantwortlich für Deal-Sourcing, Screening von Investitionsmöglichkeiten und die Unterstützung von Portfoliounternehmen. Leonard Spigt begann als Praktikant bei Active Capital Company und absolvierte einen Master in Finanzen & Investitionen an der Erasmus-Universität Rotterdam.',
          description2: '',
          description3: '',
        },
        {
          id: 18,
          title: 'Jorgos van Rooijen',
          subtitle: 'Senior Investment Manager',
          slug: 'team/jorgos-van-rooijen',
          description1:
            'Jorgos van Rooijen ist mitverantwortlich für Deal Sourcing, die Prüfung von Investitionsmöglichkeiten und die Durchführung von Plattformdeals, Add-ons und Exits.',
          description2:
            'Bevor er zu Active Capital Company kam, arbeitete Jorgos van Rooijen bei PwC in der Wirtschaftsprüfung und im Bereich Corporate Finance, wo er umfassende Erfahrungen in den Bereichen Finanz- und M&A-Transaktionen sammelte.',
          description3:
            'Jorgos hat einen Master-Abschluss in Betriebswirtschaft und einen Postgraduierten-Abschluss als Wirtschaftsprüfer (RA) von der Erasmus-Universität Rotterdam.',
        },
        {
          id: 28,
          title: 'Maximiliaan Maaskant',
          subtitle: 'Senior Investment Manager',
          slug: 'team/maximiliaan-maaskant',
          description1:
            'Maximiliaan Maaskant arbeitet als Investment Manager bei Active Capital Company. Als Investment Manager ist Max mitverantwortlich für das Deal-Sourcing, das Screening von Investitionsmöglichkeiten und die Durchführung von (Add-on) Akquisitionen sowie Desinvestitionen.',
          description2:
            'Bevor er zu Active Capital Company kam, arbeitete Max als Investment Manager bei mehreren (familieneigenen) Investmentfonds und bei der ING, wo er umfassende Erfahrungen in den Bereichen Private Equity und Transaktionsfinanzierung sammeln konnte. Max hat einen Finance Abschluss von der VU in Amsterdam.',
          description3: '',
        },
        {
          id: 25,
          title: 'Robin Baaij',
          subtitle: 'Investment Manager',
          slug: 'team/robin-baaij',
          description1:
            'Robin Baaij arbeitet als Investment Manager bei Active Capital Company. Als Investment Manager ist Robin mitverantwortlich für Deal Sourcing, die Analyse von Investitionsmöglichkeiten und Add-Ons sowie die Unterstützung von Portfoliounternehmen.',
          description2:
            'Bevor er zu Active Capital Company kam, arbeitete Robin als Associate bei Strategy & in Amsterdam und arbeitete an Deals und Transformationen im (erneuerbare) Energiesektor. Robin hat einen Master-Abschluss in Luft- und Raumfahrttechnik von der TU Delft und einen MBA von der INSEAD.',
          description3: '',
        },
        {
          id: 23,
          title: 'Johannes Sensmeyer',
          subtitle: 'Investment Manager',
          slug: 'team/johannes-sensmeyer',
          description1:
            'Johannes Sensmeyer arbeitet als Investment Manager bei Active Capital Company in unserem deutschen Büro in München. Er ist verantwortlich für die Identifizierung und Analyse von Investitionsmöglichkeiten und unterstützt unsere Portfoliounternehmen insbesondere mit Bezug zum deutschen Markt.',
          description2:
            'Johannes war Vorstandsassistent des CFO der Commerzbank AG, bevor er zur Active Capital Company kam. Zuvor sammelte er als Inhouse Consultant Erfahrungen in M&A-und Digital Transformation-Projekten.',
          description3:
            'Johannes hat einen Master-Abschluss in Betriebswirtschaftslehre der Universität Mannheim.',
        },
        {
          id: 34,
          title: 'Fabian Steppi',
          subtitle: 'Investment Manager',
          slug: 'team/fabian-steppi',
          description1:
            'Fabian Steppi arbeitet als Investment Manager bei Active Capital Company. Als Investment Manager ist Fabian mitverantwortlich für Deal-Sourcing, Screening von Investitionsmöglichkeiten und Durchführung von (Add-on) Akquisitionen und Desinvestitionen.',
          description2:
            'Bevor er zu Active Capital Company kam, arbeitete Fabian als Investment Manager bei Accursia Capital, wo er Erfahrungen im Bereich Private Equity für Industrieunternehmen sammelte. Davor begleitete er verschiedene Verkaufs- und Kauftransaktionen als M&A-Berater bei MP Corporate Finance in Wien. Fabian hat einen Bachelor-Abschluss in Finance & Management und einen MBA der Warwick Business School.',
          description3:
            '',
        },
        {
          id: 10,
          title: 'Berend Jan van Meerten',
          subtitle: 'Investment Manager',
          slug: 'team/berend-jan-van-meerten',
          description1:
            'Berend Jan van Meerten arbeitet als Investment Associate bei Active Capital Company. Berend Jan ist mitverantwortlich für das Screening von Investitionsmöglichkeiten und Add-ons, sowie für die Betreuung von Portfoliounternehmen. Berend Jan begann als Praktikant bei Active Capital Company und absolvierte einen Master in Quantitative Finance an der Erasmus-Universität Rotterdam.',
          description2: '',
          description3: '',
        },
        {
          id: 31,
          title: 'Rivkele Kastelein',
          subtitle: 'Group Controller',
          slug: 'team/rivkele-kastelein',
          description1:
            'Rivkele Kastelein ist Group Controller bei Active Capital Company. In dieser Position ist sie für Fondsverwaltung, Financial Reporting und Compliance verantwortlich.',
          description2:
            'Vor ihrem Start bei Active Capital Company hat Rivkele bei BDO Accountants gearbeitet und umfassende Erfahrungen im Bereich Financial Reporting und Buchhaltung gesammelt. Rivkele hat von 2018 bis 2020 eine berufliche Auszeit genommen, um gemeinsam mit ihrem Mann die Welt zu bereisen. Während dieser internationalen Erfahrung machte sie täglich neue Erfahrungen und lernte eine große Vielfalt an verschiedensten Kulturen kennen.',
          description3: '',
        },
        {
          id: 27,
          title: 'Miliaan Zwolle',
          subtitle: 'Investment Analyst',
          slug: 'team/miliaan-zwolle',
          description1: `Miliaan Zwolle arbeitet als Investment Analyst bei Active Capital Company. Als Analyst ist Miliaan mitverantwortlich für das Screening von Investitionsmöglichkeiten, die Durchführung von Transaktionen und die Betreuung von Portfoliounternehmen. Miliaan begann als Praktikant bei Active Capital Company und hat einen Master-Abschluss in Quantitative Finance von der Erasmus-Universität Rotterdam.`,
          description2: '',
          description3: '',
        },
        {
          id: 33,
          title: 'Constantijn van Wandelen',
          subtitle: 'Investment Analyst',
          slug: 'team/constantijn-van-wandelen',
          description1:
            'Constantijn van Wandelen ist Investment Analyst bei Active Capital Company, nachdem er im Mai 2022 als Praktikant bei uns angefangen hatte. Constantijn hat einen MSc International Management/CEMS und MSc Finance and Investments von der Erasmus Universität Rotterdam und hat zuvor Praktika im Bereich M&A und Venture Capital absolviert.',
          description2:
            'Vor seinem Studium lebte und studierte Constantijn in der Dominikanischen Republik, was sein Verständnis für kulturelle Unterschiede sowie unterschiedliche (Geschäfts-)Umgebungen geprägt hat. Derzeit lebt er in Amsterdam und genießt es, zu kochen, gutes Essen zu probieren, Sport zu treiben, Tennis zu spielen und zu lesen.',
          description3: '',
        },
        {
          id: 14,
          title: 'Célaine Tates',
          subtitle: 'Office Manager',
          slug: 'team/celaine-tates',
          description1:
            'Célaine Tates ist Office Manager bei Active Capital Company. Sie ist verantwortlich für das Sekretariat der Partner und verschiedene Facility Services innerhalb von Active Capital Company.',
          description2:
            'Célaine hat u.a. als persönliche Assistentin und Veranstaltungsmitarbeiterin bei Houthoff gearbeitet.',
          description3: '',
        }
      ],
      homepageSlider: [
        {
          id: 1,
          link: '/de/portfolio/?amphitec',
          text: '',
          imageName: 'homepage-amphitec',
          imageNameHover: '',
          imageClass: 'homepage-amphitec-logo',
        },
        {
          id: 2,
          link: '/de/portfolio/?codi_group',
          text: '',
          imageName: 'homepage-codi',
          imageNameHover: '',
          imageClass: 'homepage-codi-logo',
        },
        {
          id: 3,
          link: '/de/portfolio/?technobis',
          text: '',
          imageName: 'homepage-technobis',
          imageNameHover: '',
          imageClass: 'homepage-technobis-logo',
        },
      ],
      inlineStyleNavigation: {
        top: 100,
      },
      inlineStyleArrow: {
        width: 111,
        height: 111,
      },
      dataLoaded: false,
    };

    this.handleResize();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    const promises = [loadByKey('news', 'de'), loadByKey('portfolios', 'de')];

    Promise.all(promises)
      .then(([news, portfolio]) => {
        this.setState(
          {
            news,
            portfolio,
          },
          () => {
            this.setState({
              dataLoaded: true,
            });

            const queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams.has('scroll-to')) {
              const scrollToElement = queryParams.get('scroll-to');
              scrollTo(document.getElementById(scrollToElement));
            }
          }
        );
      })
      .catch(err => console.log('ERR', err));
  }

  handleResize = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  toggleDetails = (e, key) => {
    e.preventDefault();
    const elements = document.querySelectorAll('#team-member-' + key);

    if (elements.length === 0) {
      return;
    }

    this.toggleDetailsOfAllTeamMembers(elements);

    elements.forEach(element => {
      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.toggle('opened');
      element.classList.toggle('opened');
    });
  };

  toggleDetailsOfAllTeamMembers = skippableElements => {
    const allTeamMembers = document.querySelectorAll('.team-member-info');
    allTeamMembers.forEach(element => {
      if (skippableElements) {
        const foundSkippableElement = [...skippableElements].find(e =>
          e.isSameNode(element)
        );
        if (foundSkippableElement) {
          return;
        }
      }

      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.remove('opened');
      element.classList.remove('opened');
    });
  };

  handleNextTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slideNext();
  };

  handlePrevTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slidePrev();
  };

  setInitialItemsHeight = () => {
    setTimeout(() => {
      const slideItem1 = document.getElementById('slide-item-1');
      let initialHeight = 475;
      if (slideItem1 !== null) {
        initialHeight =
          slideItem1.childNodes[0].childNodes[0].childNodes[0].offsetHeight;
      }
      const imageWrappers = document.querySelectorAll('.team-image-wrapper');
      imageWrappers.forEach(element => {
        element.style.height = initialHeight.toString() + 'px';
      });
    }, 1000);
  };

  /**
   * Checks if object is empty.
   */
  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  toggleMore = () => {
    this.setState({ moreLoaded: !this.state.moreLoaded });
  };

  portfolioData() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'current') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/de/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/de/portfolio/?' + val.slug} className="more-info">
              Mehr
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">Keine Daten vorhanden</span>;
  }

  divestedPortfolios() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'divested') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/de/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/de/portfolio/?' + val.slug} className="more-info">
              Mehr
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">Keine Daten vorhanden</span>;
  }

  sendContactMail = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.subject === '' ||
      this.state.message === ''
    ) {
      /*
        store.addNotification({
          message: 'Please fill all fields.',
          title: 'Warning',
          type: 'warning',
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        */
      this.setState({ fillFields: true });
      setTimeout(() => {
        this.setState({ fillFields: false });
      }, 2000);
      return;
    }

    this.setState({ spinnerShow: true });
    setTimeout(() => {
      this.setState({ spinnerShow: false });
      this.setState({ successMsg: true });
      setTimeout(() => {
        this.setState({ successMsg: false });
      }, 3000);
    }, 2000);

    return;
    const data = {
      to: ['andric.stevan@yahoo.com', 'nikola@cru.rs'],
      from: this.state.email,
      replyTo: this.state.email,
      subject: this.state.subject,
      text: this.state.message,
      html:
        this.state.name +
        ' sent you a message: <br><p>' +
        this.state.message +
        '</p>',
    };

    this.setState({ spinnerShow: true });
    axios
      .post(`${this.apiEndPoint}/email/`, data)
      .then(response => {
        store.addNotification({
          message: 'Ihre Nachricht wurde versandt.',
          title: 'Success',
          type: 'success',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch(err => {
        console.error(err);
        store.addNotification({
          message:
            'Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.',
          title: 'Error',
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .finally(() => {
        this.setState({ spinnerShow: false });
      });
  };

  notificationMesagge() {
    return (
      <div className="success-mesagge">
        <p>Wir haben Ihre Nachricht erhalten</p>
        <p>und melden uns so bald wie möglich.</p>
      </div>
    );
  }

  filedsMesagge() {
    return (
      <div className="fields-mesagge">
        <p>Angaben in allen Feldern erforderlich.</p>
      </div>
    );
  }

  /**
   * It generates list of news items to be shown.
   */
  listItems() {
    let items = this.state.news;
    let options = { month: 'long', day: '2-digit', year: 'numeric' };
    if (items === undefined || items.length === 0) {
      return false;
    }

    return (
      <div className="row news-container">
        {items.map((val, index) => {
          if (index > 5) {
            return;
          }

          return (
            <Link
              to={'/de/news-details/?' + val.slug}
              className="col-lg-6 news-box"
              key={index}
            >
              <div className="float-left news-image">
                <img
                  width="100%"
                  src={
                    val.heading_image !== undefined &&
                    val.heading_image !== null
                      ? `${this.apiEndPoint}/${val.heading_image.url}`
                      : noImage
                  }
                  alt=""
                />
              </div>
              <div className="large-padding news-text">
                <h5 className="news-title">{val.img_heading}</h5>
                <div className="news-published">
                  <div
                    className="cursor--pointer"
                    onClick={e => openUrl(val.source_url)}
                  >
                    {val.source || 'Pressemitteilung'},{' '}
                    {new Intl.DateTimeFormat('de-DE', options).format(
                      new Date(val.published)
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    const { dataLoaded, activeContactTab } = this.state;
    return (
      <Layout dataLoaded={dataLoaded}>
        <Spinner show={this.state.spinnerShow} />
        <header className="homepage-header-wrapper" id="header">
          <span id="homepage-indicator"></span>

          <AliceCarousel
            responsive={this.homepageResponsive}
            dotsDisabled={true}
            autoPlay={true}
            fadeOutAnimation={true}
            autoPlayInterval={5000}
            playButtonEnabled={false}
            buttonsDisabled={true}
            preventEventOnTouchMove={false}
            stopAutoPlayOnHover={false}
            mouseDragEnabled={false}
            duration={2000}
          >
            {
              (this.items = this.state.homepageSlider.map((item, key) => (
                <div className={`masthead masthead-${item.id}`} key={key}>
                  <div className="page-wrapper absolute-wrapper-header-logo">
                    {/* <span className="background-image-text">Background image</span> */}
                    <span className="text-right homepage-mobile-hidden">
                      <a href={item.link} rel="noopener noreferrer">
                        {/*<img className="left-spacing-medium default-icon-state" src={require(`../assets/images/icons/icon-${item.imageName}.svg`)} alt=""/>*/}
                        {/*<img className="left-spacing-medium hover-icon-state" src={require(`../assets/images/icons/icon-${item.imageNameHover}.svg`)} alt=""/>*/}
                        <img
                          className={item.imageClass}
                          src={require(`../assets/images/homepage-icons/${item.imageName}.png`)}
                          alt=""
                        />
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>
          <div className="container d-flex align-items-center homepage-absolute-wrapper">
            <div className="center-block text-center col-sm-12">
              <h1 className="mx-auto my-0 homepage-main-text">
                Wir sind ein unabhängiger unternehmerischer Investor, der
                Wertsteigerung durch aktive Transformation realisiert
              </h1>
              <div className="header-buttons-wrapper">
                <Scroll type="id" element="our-work">
                  <a
                    href="#our-work"
                    className="button-link button-white-wrapper"
                  >
                    <button className="button button-white">
                      Unser Ansatz
                      <img src={iconCaretDown} alt="" />
                    </button>
                  </a>
                </Scroll>

                <Link
                  to="/de/investment-focus"
                  className="button-link button-orange-wrapper"
                >
                  <button className="button button-orange button-orange-de">
                    Investitionsfokus
                    <img src={iconCaretRight} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="header-quick-links page-wrapper absolute-wrapper-header-quick-links container page-wrapper">
            <span className="three-column custom-hompage-three-column homepage-mobile-hidden">
              <a
                href="https://fundrbird.com/login?agent_slug=active-capital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="right-spacing-medium default-icon-state"
                  src={iconLogin}
                  alt=""
                />
                <img
                  className="right-spacing-medium hover-icon-state"
                  src={iconLoginHover}
                  alt=""
                />
                Investor log in
              </a>
            </span>
            <span className="three-column text-center">
              <Scroll type="id" element="about">
                <img
                  className="default-icon-state icon-down"
                  src={iconDown}
                  alt=""
                />
              </Scroll>
            </span>
            <span className="clearfix"></span>
          </div>
        </header>

        <section className="container content-wrapper" id="about">
          <div className="homepage-title text-center">
            <h2 className="text-left">
              Unser Antrieb: Leidenschaft, Unternehmergeist, Ergebnis
            </h2>
            <span className="horizontal-line gray-background homepage-line homepage-desktop-hidden"></span>
            <div className="col-sm-6 text-left float-left custom-right-padding">
              <p>
                Active Capital Company investiert in kleine und mittlere
                Unternehmen aus den Niederlanden oder Deutschland mit einem
                Umsatz zwischen € 10 Mio. und € 100 Mio., die in der
                industriellen Produktion, im (technischen) Großhandel oder im
                Bereich unternehmensbezogene Dienstleistungen tätig sind.
              </p>
            </div>
            <div className="col-sm-6 text-left float-left custom-left-spacing">
              <p>
                Wir wurden gegründet von Unternehmern mit einer Leidenschaft für
                industrielle Umgebungen und unterstützen Unternehmer nach
                unseren drei Prinzipien der Wertsteigerung: Geografische
                Expansion, Nachhaltigkeit und Innovation.
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <span className="horizontal-line gray-background homepage-line homepage-mobile-hidden"></span>
          <div className="about-wrapper">
            <div className="col-md-6 text-left float-left custom-right-spacing homepage-disable-padding">
              <div className="gray-background medium-padding">
                <h3>
                  Nachhaltige Erträge für unsere Stakeholder durch unsere drei
                  Prinzipien der Wertsteigerung
                </h3>
              </div>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing">
              <img
                className="about-geographic"
                src={iconGeography}
                alt="Geographic expansion"
              />
              <h4 className="fs-1_5rem">
                Geografische
                <br />
                Expansion
              </h4>
              <p>
                Wir erweitern die geografische Reichweite durch weltweite
                Standorte und neue Vertriebsteams
              </p>
            </div>
            <div className="clearfix"></div>
            <div className="col-sm-6 float-left custom-right-spacing custom-top-spacing">
              <img src={iconInovation} alt="Innovation" />
              <h4 className="fs-1_5rem">Innovation</h4>
              <p>
                Wir beschleunigen Wachstum durch Investitionen in Zukäufe und
                Produktentwicklung
              </p>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing custom-top-spacing">
              <img src={iconPlanet} alt="Impact" />
              <h4 className="fs-1_5rem">Impact</h4>
              <p>
                Wir erhöhen die Rentabilität durch unseren Fokus auf
                Nachhaltigkeitsaspekte und investieren in Mitarbeiter, Umwelt
                und Unternehmenskultur
              </p>
            </div>
            <div className="clearfix"></div>
          </div>

          <div className="investment-criteria">
            <Link to="/de/investment-focus">
              <img src={iconRightBlue} alt="Investment focus" />
              Investitionsfokus
            </Link>
          </div>

          <div className="key-facts-wrapper">
            <div className="col-lg-6 float-left key-facts-image">
              <img
                src={keyFactsImage}
                alt="Key facts"
                className="right-padding"
              />
            </div>

            <div className="col-lg-6 float-left">
              <h3>Key Facts</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
              <h4>
                3711 <br className="visible-mobile" />
                <sup>Mitarbeiter</sup>
              </h4>
              <p>in 10 Portfoliounternehmen mit Standorten in 11 Ländern</p>
              <h4>
                € 184 Millionen
                <br className="visible-mobile" />
                <sup> verwaltetes Vermögen</sup>
              </h4>
              <p>in 4 Fonds</p>
            </div>

            <div className="clearfix"></div>
          </div>

          <div className="our-work-wrapper" id="our-work">
            <div className="col-sm-12">
              <h3 className="top-separator">Der ACC Weg</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
            </div>
            <div className="our-work-wrapper-section">
              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-1" className="link-label">
                  <span className="blue-color mobile-hidden">
                    <strong>Phase 1</strong>
                  </span>
                  <span className="horizontal-line blue-background no-side-margin mobile-hidden"></span>
                  <img src={iconMoney} alt="Phase 1" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-1"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transaktion</h4>
                      <p>Entscheidungsgrundlagen für unsere Beteiligung</p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(1)}
                          className="list-wrapper-1"
                        >
                          <span className="list-basic-1">
                            Prüfung der Investition anhand unserer Kriterien
                          </span>
                          <span className="list-detailed-1 hidden">
                            TUmfassende Prüfung der Investitionsmöglichkeit
                            anhand{' '}
                            <a className="orange-link" href="/investment-focus">
                              unserer Kriterien
                            </a>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(2)}
                          className="list-wrapper-2"
                        >
                          <span className="list-basic-2">
                            Durchführung der Due Diligence
                          </span>
                          <span className="list-detailed-2 hidden">
                            Durchführung der Due Diligence durch unser
                            <Scroll type="id" element="team">
                              <a href="?scroll-to=team" className="orange-link">
                                {' '}
                                Team{' '}
                              </a>
                            </Scroll>{' '}
                            mit Unterstützung unserer
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                Partner{' '}
                              </a>
                            </Scroll>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(3)}
                          className="list-wrapper-3"
                        >
                          <span className="list-basic-3">
                            Präsentation eines unverbindlichen Angebots
                          </span>
                          <span className="list-detailed-3 hidden">
                            Präsentation eines unverbindlichen Angebots und
                            kommerzielle Due Diligence zur Strategieplanung
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(4)}
                          className="list-wrapper-4"
                        >
                          <span className="list-basic-4">
                            Entwurf eines 100-Tage-Plans
                          </span>
                          <span className="list-detailed-4 hidden">
                            Erstellung unseres 100-Tage-Plans in enger
                            Zusammenarbeit mit dem Verkäufer und
                            Branchenexperten
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-1"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-2" className="link-label">
                  <span className="orange-color mobile-hidden">
                    <strong>Phase 2</strong>
                  </span>
                  <span className="horizontal-line orange-background no-side-margin mobile-hidden"></span>
                  <img src={iconTransform} alt="Phase 2" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-2"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transformation</h4>
                      <p>
                        Planung und Ausführung über die Dauer unserer
                        Beteiligung
                      </p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(5)}
                          className="list-wrapper-5"
                        >
                          <span className="list-basic-5">
                            Aktive Vor-Ort-Unterstützung unserer
                            Portfoliounternehmen
                          </span>
                          <span className="list-detailed-5 hidden">
                            An bis zu vier Tagen pro Woche arbeiten wir mit
                            unseren
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                Portfoliounternehmen{' '}
                              </a>
                            </Scroll>{' '}
                            an der Umsetzung der ACC-Maßnahmen und schaffen so
                            Kapazitäten für die wachstumsorientiere Ausrichtung
                            des Unternehmens.
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(6)}
                          className="list-wrapper-6"
                        >
                          <span className="list-basic-6">
                            Schnelleres Wachstum
                          </span>
                          <span className="list-detailed-6 hidden">
                            Durch Investitionen in Innovation und
                            Produktentwicklung sowie durch Zukäufe beschleunigen
                            wir das Wachstum
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(7)}
                          className="list-wrapper-7"
                        >
                          <span className="list-basic-7">
                            Neue Märkte erschließen
                          </span>
                          <span className="list-detailed-7 hidden">
                            Wir erschließen Märkte durch neue Standorte und
                            Vertriebsteams weltweit
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(8)}
                          className="list-wrapper-8"
                        >
                          <span className="list-basic-8">
                            Unternehmenskultur stärken
                          </span>
                          <span className="list-detailed-8 hidden">
                            Stärkung der Unternehmenskultur mit starkem Fokus
                            auf Nachhaltigkeit
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-2"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-3" className="link-label">
                  <span className="soft-red-color mobile-hidden">
                    <strong>Phase 3</strong>
                  </span>
                  <span className="horizontal-line soft-red-background no-side-margin mobile-hidden"></span>
                  <img src={iconMap} alt="Phase 3" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-3"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Positionierung & Verkauf</h4>
                      <p>
                        Planung und Durchführung
                        <br />
                        des Verkaufs
                      </p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(9)}
                          className="list-wrapper-9"
                        >
                          <span className="list-basic-9">
                            Fokus auf Verkaufspositionierung
                          </span>
                          <span className="list-detailed-9 hidden">
                            Während der Beteiligung ist eine optimale
                            Positionierung für den Verkauf die Grundlage jeder
                            strategischen Entscheidung.
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(10)}
                          className="list-wrapper-10"
                        >
                          <span className="list-basic-10">
                            Ermittlung des optimalen Verkaufszeitpunkts
                          </span>
                          <span className="list-detailed-10 hidden">
                            Ermittlung des optimalen Verkaufszeitpunkts für das
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                Portfoliounternehmen{' '}
                              </a>
                            </Scroll>
                            <ul className="the-way-list">
                              <li>Organisation</li>
                              <li>Unternehmenskultur</li>
                              <li>Wachstum</li>
                              <li>Innovation</li>
                            </ul>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-3"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>

        <section
          className="container content-wrapper business-invested mobile-padding"
          id="portfolio"
        >
          <h3 className="top-separator">Unser Portfolio</h3>
          <div className="tabs-wrapper">
            <div
              className={this.state.firstTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab1}
            >
              Aktuelle Beteiligungen
            </div>
            <span className="vertical-separator homepage-mobile-hidden"></span>
            <hr className="short-horizontal-line" />
            <div
              className={this.state.secondTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab2}
            >
              Vergangene Beteiligungen
            </div>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>

          <div className="row">
            <input
              type="checkbox"
              className="read-more-state"
              id="portfolio-1"
            />
            <div
              className={
                'current-investment-wrapper read-more-wrap' +
                (!this.state.firstTab ? ' hide' : '') +
                (this.state.moreLoaded ? ' more-loaded' : '')
              }
            >
              {this.portfolioData()}
              <label
                htmlFor="portfolio-1"
                className="read-more-trigger"
                onClick={this.toggleMore}
              ></label>
            </div>

            <div
              className={
                'divested-wrapper' + (!this.state.secondTab ? ' hide' : '')
              }
            >
              {this.divestedPortfolios()}
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <section className="team-title-container" id="team">
          <h3 className="text-center top-separator section-spaces-bottom">
            Lernen Sie unser Team kennen
          </h3>
        </section>
        <section className="team-wrapper col-sm-12">
          <AliceCarousel
            responsive={this.responsive}
            dotsDisabled={true}
            buttonsDisabled={true}
            ref={el => (this.Carousel = el)}
            onInitialized={this.setInitialItemsHeight}
            onResized={this.setInitialItemsHeight}
          >
            {
              (this.items = this.state.teamMembers.map((item, key) => (
                <div
                  id={`slide-item-` + key}
                  onClick={e => this.toggleDetails(e, key)}
                  key={key}
                >
                  <a href="#" key={key}>
                    <div className="team-image-wrapper">
                      <img
                        src={require(`../assets/images/team/team-${item.id}.jpg`)}
                        alt={item.title}
                        id={`slider-item-` + key}
                      />
                    </div>
                    <div className="title">{item.title}</div>
                    <div className="subtitle">{item.subtitle}</div>
                  </a>
                  <div className="team-member-info" id={`team-member-${key}`}>
                    <span className="member-info">
                      <p className="black-color">{item.description1}</p>
                      <p>{item.description2}</p>
                      <p>{item.description3}</p>
                      {item.description4 && <p>{item.description4}</p>}
                    </span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>

          <div
            className="navigation-buttons-container"
            style={this.state.inlineStyleNavigation}
          >
            <button
              className="alice-carousel__prev-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handlePrevTeamMemberClick()}
            >
              <p className="alice-carousel__prev-btn-item">
                <span></span>
              </p>
            </button>
            <button
              className="alice-carousel__next-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handleNextTeamMemberClick()}
            >
              <p className="alice-carousel__next-btn-item">
                <span></span>
              </p>
            </button>
          </div>
        </section>

        {/* End of team section */}

        <section
          className="container content-wrapper responsible-investments-title-container"
          id="responsible-investments"
        >
          <h3 className="text-center top-separator section-spaces-bottom">
            Verantwortungsvolles Investieren
          </h3>
          <h4 className="text-center section-spaces-bottom">
            Bei Active Capital Company berücksichtigen wir die Auswirkungen
            unserer Portfoliounternehmen auf die Umwelt und die Gesellschaft.
            Wir unterteilen Impact-Themen in drei Säulen:
          </h4>
        </section>
        <section className="container content-wrapper responsible-investments-wrapper">
          <div className="row">
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconReporting}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Reporting & regulierung
                </span>
                <span className="responsible-investments-text">
                  Wir ermutigen unsere Unternehmen zu mehr Transparenz in Bezug
                  auf ESG-Themen (Environmental, Social und Governance) und
                  weiteren nicht-finanziellen Aspekten
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconMagnifierRemove}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Umweltbelastung reduzieren
                </span>
                <span className="responsible-investments-text">
                  Reduzierung der Umweltbelastung des laufenden Geschäfts
                  hinsichtlich ESG-bezogenen Themen wie CO2- Austoß und
                  Wasserverbrauch. Das Management und ACC wird gegebenenfalls
                  von Beratern oder Drittparteien unterstützt
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconSustainable}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Mehrwehrt schaffen
                </span>
                <span className="responsible-investments-text">
                  Geschäftsmodelle werden zu nachhaltigeren und
                  widerstandsfähigeren Modellen transformiert. Zu Beginn einer
                  Portfolioinvestition werden Nachhaltigkeitsaspekte als
                  Grundlage für die zukünftige Strategie des Unternehmens
                  definiert
                </span>
              </div>
            </div>
          </div>

          <Link
            to="https://activecapitalcompany.nl/ACC_ESG-related_disclosures_Nov_2022.pdf"
            target="_blank"
            className="more-info"
          >
            Hier finden Sie unsere ESG-bezogenen Erklärungen
          </Link>
        </section>

        {/* Start of Timbo section */}

        <section
          className="container content-wrapper corporate-wrapper col-sm-12"
          id="corporate-responsibility"
        >
          <div className="">
            <div className="col-md-6 float-left corporate-first-wrapper">
              <div className="timbo-image-wrapper desktop-hidden">
                <img src={timboAfrica} alt="Timbo Africa Foundation" />
              </div>
              <img
                src={timboLogo}
                alt="Timbo Africa Foundation"
                width="160px"
                className="timbo-logo"
              />
              <br />
              <h3>Timbo Africa Stiftung</h3>
              <br />
              <p>
                Die Timbo Stiftung setzt sich für den Schutz der Wildtiere in
                Afrika ein, insbesondere durch die Schaffung von
                Bewegungskorridoren zwischen bestehenden Naturgebieten.
                Zusätzlich wird die lokale Jugend durch Forschung, Ausbildung
                und Schulungen eingebunden und eine Perspektive für Karrieren im
                Naturschutz geschaffen.
              </p>
              <p>
                Die Stiftung ist vor allem in Botswana und Südafrika tätig.
                Weiterhin sammelt sie Mittel zur Unterstützung der
                Krebsforschung in den Niederlanden.
              </p>
              <br />
              <Link to="/de/corporate-responsibility" className="button-link">
                <button className="button button-blue">
                  Mehr erfahren
                  <img src={iconCaretRight} alt="" />
                </button>
              </Link>
              <br />
              <br />
            </div>
            <div className="col-md-6 float-left timbo-image-wrapper mobile-hidden">
              <img src={timboAfrica} alt="Timbo Africa Foundation" />
            </div>
            <div className="clearfix"></div>
          </div>
        </section>

        <section
          className="container content-wrapper news-wrapper col-sm-12 mobile-padding "
          id="news"
        >
          <h3 className="top-separator section-spaces-bottom">
            Aktuelle
            <br />
            Entwicklungen
          </h3>
          <div className="view-all-news top-separator-large custom-view-all-news-link">
            <p>
              <Link to="/de/news-overview">Alle anzeigen</Link>
            </p>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>
          <div className="bottom-spacing">
            {this.listItems()}
            <Link
              className="view-all-news-and-articles top-separator-large"
              to="/de/news-overview"
            >
              <p>Alle anzeigen</p>
            </Link>
            <div className="clearfix"></div>
          </div>
        </section>

        <section className="contact-wrapper" id="contact">
          <span className="contact-info-text filter--text-shadow--dark text-desktop-hidden">
            Kontaktieren Sie uns{' '}
          </span>
          <div className="filter--darken-image">
            <img
              src={contactImage}
              alt="Contact"
              className="background-image-contact-desktop background-image text-mobile-hidden"
            />
          </div>
          <div className="filter--darken-image">
            <img
              src={contactImageMobile}
              alt="Contact"
              className="background-image text-desktop-hidden"
            />
          </div>
          <div className="container page-wrapper contact-info-wrapper">
            <div className="col-md-6 float-left contact-form-wrapper">
              <div className="contact-info-tabs">
                {CONTACT_TABS.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        activeContactTab === index ? 'active' : ''
                      } contact-tab-link`}
                      onClick={this.onTabChange(index)}
                    >
                      {tab.title}
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="google-map">
                  <iframe
                    title="Google Map"
                    src={CONTACT_TABS[activeContactTab].mapUrl}
                    width="100%"
                    height="450"
                    frameBorder="0"
                  ></iframe>
                </div>

                <div className="contact-form">
                  <form id="contact-form">
                    <input
                      type="text"
                      className="name-field"
                      id="name"
                      name="name"
                      placeholder="Ihr Name"
                      value={this.state.name}
                      required
                      onChange={this.handleNameChange}
                    />

                    <input
                      type="text"
                      className="email-field"
                      id="email"
                      name="email"
                      placeholder="Ihre E-Mail-Adresse"
                      value={this.state.email}
                      required
                      onChange={this.handleEmailChange}
                    />

                    <input
                      type="text"
                      className="subject-field"
                      id="subject"
                      name="subject"
                      placeholder="Betreff"
                      value={this.state.subject}
                      required
                      onChange={this.handleSubjectChange}
                    />

                    <textarea
                      className="message-field"
                      id="message"
                      name="message"
                      placeholder="Ihre Nachricht"
                      value={this.state.message}
                      required
                      onChange={this.handleMessageChange}
                    ></textarea>

                    <button
                      className="button button-blue"
                      type="button"
                      onClick={this.sendContactMail}
                    >
                      <span className="button-text">Nachricht senden</span>
                      <img src={iconSubmit} alt="" />
                    </button>
                  </form>
                </div>
                {this.state.successMsg ? (
                  this.notificationMesagge()
                ) : (
                  <div></div>
                )}
                {this.state.fillFields ? this.filedsMesagge() : <div></div>}
              </div>
            </div>

            <div className="col-md-6 float-left no-left-padding">
              <div className="contact-info">
                <span className="contact-info-text filter--text-shadow--dark text-mobile-hidden">
                  Kontaktieren Sie uns
                </span>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconSuitcase}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    Active Capital Company B.V.
                  </span>
                  <span className="clearfix"></span>
                </p>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconAddress}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <span className="contact-info-right-title">
                      {CONTACT_TABS[0].title}
                    </span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/mUFBVCqkTefWpyqu9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vossiusstraat 13A
                      <span className="custom-align">1071 AC Amsterdam</span>
                    </a>
                    <br />
                    <span className="contact-info-right-border"></span>
                    <br />
                    <span className="contact-info-right-title">
                      {CONTACT_TABS[1].title}
                    </span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/gHVZcrpjRnnpmcBh9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maffeistraße 3
                      <span className="custom-align">80333 München</span>
                    </a>
                  </span>

                  <span className="clearfix"></span>
                </p>

                <BrowserView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-right-section">
                      <span className="contact-info-right-title">NL: </span>{' '}
                      {config.companyPhoneNumber}
                      <br />
                      <br />
                      <span className="contact-info-right-title">
                        DE:{' '}
                      </span>{' '}
                      {config.companyPhoneNumberGermany}
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </BrowserView>
                <MobileView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-phone-numbers">
                      <a
                        className="contact-info-right-section contact-info-phone"
                        href={config.companyPhoneUrl}
                      >
                        <span className="contact-info-right-title">NL: </span>
                        {config.companyPhoneNumber}
                      </a>
                      <a
                        className="contact-info-right-section"
                        href={config.companyPhoneUrlGermany}
                      >
                        <span className="contact-info-right-title">DE: </span>
                        {config.companyPhoneNumberGermany}
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </MobileView>

                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconMessage}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <a href={'mailto:' + config.companyEmail}>
                      {config.companyEmail}
                    </a>
                  </span>
                  <span className="clearfix"></span>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </section>
        <Scroll type="id" element="header">
          <div className="back-to-top-wrapper">
            <a href="?scroll-to=header">
              <i className="fas fa-angle-double-up"></i>
              <br />
              <span className="back-to-top">Zurück zu Start</span>
            </a>
          </div>
        </Scroll>
      </Layout>
    );
  }

  showTab1 = () => {
    this.setState({
      firstTab: true,
      secondTab: false,
    });
  };

  showTab2 = () => {
    this.setState({
      firstTab: false,
      secondTab: true,
    });
  };

  onTabChange = index => () => {
    this.setState({
      activeContactTab: index,
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubjectChange = e => {
    this.setState({ subject: e.target.value });
  };

  handleMessageChange = e => {
    this.setState({ message: e.target.value });
  };

  handleListCollapse = index => {
    var listBasic = document.querySelector('.list-basic-' + index);
    var listDetailed = document.querySelector('.list-detailed-' + index);
    var listWrapper = document.querySelector('.list-wrapper-' + index);

    listBasic.classList.toggle('hidden');
    listDetailed.classList.toggle('hidden');
    listWrapper.classList.toggle('opened');
  };
}
